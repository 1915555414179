import React from 'react';
import Countdown from './Countdown';

const App = () => {
  // Set your target date here
  const targetDate = new Date('2025-03-03T23:59:59');

  return (
    <Countdown targetDate={targetDate} />
  );
};

export default App;

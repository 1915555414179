import React, { useState, useEffect } from 'react';

const Countdown = ({ targetDate }) => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  function calculateTimeLeft() {
    const difference = targetDate - new Date();
    if (difference <= 0) return null;

    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((difference / (1000 * 60)) % 60);
    const seconds = Math.floor((difference / 1000) % 60);

    return { days, hours, minutes, seconds };
  }

  if (!timeLeft) {
    return <h1 style={styles.text}>Time's up!</h1>;
  }

  return (
    <div style={styles.container}>
      <h1 style={styles.text}>
        {timeLeft.days}d {timeLeft.hours}h {timeLeft.minutes}m {timeLeft.seconds}s
      </h1>
    </div>
  );
};

const styles = {
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      backgroundColor: 'white',
      padding: '20px', // Add some padding for smaller screens
      boxSizing: 'border-box', // Ensure padding is included in width/height calculations
    },
    text: {
      color: 'black',
      fontSize: '4vw', // Use viewport width units for responsive font size
      fontFamily: 'Arial, sans-serif',
      textAlign: 'center', // Center the text
    },
  };
  

export default Countdown;
